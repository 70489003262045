<template>
  <UModal v-model="showDialog">
    <UCard :ui="{ ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
      <template #header>
        <div class="flex items-center justify-between">
          <h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-white">
            Edit Prompt
          </h3>
          <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" class="-my-1"
            @click="showDialog = false" />
        </div>
      </template>

      <div class="flex flex-col gap-4">
        <UFormGroup label="Title" class="font-fakt-blond">
          <UInput v-model="promptData.title" />
        </UFormGroup>

        <UFormGroup label="Prompt" class="font-fakt-blond">
          <UTextarea v-model="promptData.prompt" :rows="4" />
        </UFormGroup>
        
        <div class="flex gap-2">
          <UFormGroup label="Available in..." class="font-fakt-blond min-w-[180px]">
            <USelectMenu v-model="promptData.scope" :options="scopeOptions" value-attribute="value" option-attribute="label" multiple />
          </UFormGroup>

          <UFormGroup label="Visible for..." class="font-fakt-blond min-w-[160px]">
            <USelectMenu v-model="promptData.visibility" :options="visibilityOptions" value-attribute="value" option-attribute="label" />
          </UFormGroup>
        </div>
      </div>
      <template #footer>
        <div class="flex items-center justify-between">
          <UButton :loading="loading" color="gray" @click="showDialog = false"
            label="Cancel" />
          
          <UButton :loading="loading" color="wobby-purple" @click="submit"
            label="Update Prompt" />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup lang="ts">
import type { SavedPrompt } from '~/types';

const identityStore = useIdentityStore();
const { updateUserPrompt } = useIdentityService();
const { user } = storeToRefs(identityStore);

const emit = defineEmits(['updated']);

const showDialog = ref(false)
const loading = ref(false)
const promptData = ref({
  id: '',
  title: '',
  prompt: '',
  scope: [] as string[],
  visibility: 'private'
})

const scopeOptions = ref([
  { label: 'Chat', value: 'chat' },
  { label: 'Research assistant', value: 'research-assistant' },
  { label: 'Home search', value: 'home-search' }
])

const visibilityOptions = ref([
  { label: 'Only me', value: 'private' },
  { label: 'My organization', value: 'public' }
])


const show = (prompt: SavedPrompt) => {
  promptData.value = {
    id: prompt.id,
    title: prompt.title,
    prompt: prompt.prompt,
    scope: prompt.scope,
    visibility: prompt.sharedWithOrganization ? 'public' : 'private'
  }

  showDialog.value = true;

  loadPromptName();
}

const loadPromptName = () => {
  
}

const submit = async () => {
  loading.value = true;

  let prompt = {
    title: promptData.value.title,
    prompt: promptData.value.prompt,
    scope: promptData.value.scope,
    sharedWithOrganization: promptData.value.visibility === 'public'
  }

  await updateUserPrompt(user.value!.id, promptData.value!.id, prompt);
  
  emit('updated', promptData.value);

  loading.value = false;
  showDialog.value = false;
  promptData.value = {
    id: '',
    title: '',
    prompt: '',
    scope: [],
    visibility: 'private'
  }
}

defineExpose({
  show
})
</script>